import React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'

// Components
import Layout from '../components/Layout'
import PageTitle from '../components/Layout/PageTitle'
import Pagination from '../components/Layout/Pagination'
import PostPreview from '../components/Blog/PostPreview'

const BlogTemplate = ({ location, data, pageContext }) => {
  const { currentPage, numPages, name } = pageContext
  const posts = data?.allMdx?.nodes
  const defaultImg = data?.file

  const isFirst = currentPage === 1
  const isLast = currentPage === numPages
  const prevPage = currentPage - 1 === 1 ? `/${name}` : `/${name}/${(currentPage - 1).toString()}`
  const nextPage = `/${name}/${(currentPage + 1).toString()}`

  return (
    <Layout location={location} title="Blog">
      <main>
        <PageTitle
          title={'Trainmaster'}
          is404={false}
          breadArray={[
            { name: 'blog', href: 'blog' },
            { name: `page ${currentPage}`, href: '' },
          ]}
        />
        <section className="container">
          <div className="pb-lg-5">
            <div className="row">
              {posts.map(({ id, frontmatter }) => {
                const { title, slug, date, featureImg, category } = frontmatter
                let img = defaultImg,
                  isFeature = false
                if (featureImg !== null) {
                  img = featureImg
                  isFeature = true
                }

                return (
                  <article key={id} className="col-12 col-sm-6 col-xl-4 mb-4">
                    <PostPreview
                      title={title}
                      slug={`/${slug}`}
                      date={date}
                      img={img}
                      cats={category}
                      isFeature={isFeature}
                    />
                  </article>
                )
              })}
            </div>
          </div>
          {isFirst !== true && isLast !== true && (
            <Pagination isFirst={isFirst} isLast={isLast} prevPage={prevPage} nextPage={nextPage} />
          )}
        </section>
      </main>
    </Layout>
  )
}

BlogTemplate.propTypes = {
  location: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
}

export default BlogTemplate

export const blogQuery = graphql`
  query blogQuery($skip: Int!, $limit: Int!) {
    file(relativePath: { eq: "blog-post.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    allMdx(
      sort: { order: DESC, fields: frontmatter___date }
      filter: { fileAbsolutePath: { regex: "/blog/" } }
      limit: $limit
      skip: $skip
    ) {
      nodes {
        id
        frontmatter {
          title
          slug
          date
          keywords
          featureImg {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
`
