import React from 'react'
import PropTypes from 'prop-types'

// Components
import BtnOutline from '../Buttons/BtnOutline'

const Pagination = ({ isFirst, isLast, prevPage, nextPage }) => {
  return (
    <div className="d-flex justify-content-center mt-5">
      {!isFirst && (
        <div className="px-1">
          <BtnOutline href={prevPage} rel="prev">
            ← Previous Page
          </BtnOutline>
        </div>
      )}
      {!isLast && (
        <div className="px-1">
          <BtnOutline href={nextPage} rel="next">
            Next Page →
          </BtnOutline>
        </div>
      )}
    </div>
  )
}

Pagination.propTypes = {
  isFirst: PropTypes.bool.isRequired,
  isLast: PropTypes.bool.isRequired,
  prevPage: PropTypes.string.isRequired,
  nextPage: PropTypes.string.isRequired,
}

export default Pagination
